import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '../../entity';
import store from '@/admin/store';
import { getLotCard, editLotCard, createLotCard, getLotsStatuses, withdrawLotFromSales, updateLotsStatus, sendLotPublish } from '@/admin/api/lots';
import EntityModel from './entityModel';
import { getCategoryHierarchyList } from '@/admin/api/category';
import {
    capitaize,
    formatCreatedAt,
    generateFilter,
    generateSort,
    getApiErrorMessage,
    imageFileToBase64,
    showError,
    showMessage,
} from '@/lib/utils/Utils';
import { getAuctionHouseCard, getAuctionHouseList } from '@/admin/api/auctionHouse';
import {
    processListForSuggestionsMultiple,
    processCategoryListForSuggestionsMultiple,
    // getTreeCategoryListSelected,
    // getTreeCategoryListUnselected,
    getTreeCategoryFullListSelected,
    getContainsCategory,
    getFullCategory,
    HierarchyCategorySelectItem,
    processListForSelect,
} from '@/lib/utils/form';
import { getPainterCard, getPainterList } from '@/admin/api/painter';
import { getGalleryCard, getGalleryList } from '@/admin/api/gallery';
import modalParams from './modalParams';
import ImageInputEntity from '@/lib/formFactory/imageInputEntity';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { getCatalogAuctionCard, getCatalogAuctionList } from '@/admin/api/catalogAuction';
import { ImageInput } from '@/lib/formFactory/imageInput.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { Input } from '@/lib/formFactory/input.interface';
import { RadioItem, RadioList } from '@/lib/formFactory/radio.interface';

import { tabsNav } from './tabs';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';

import SiteModule from '@/admin/store/site';
import { tabsNavInterface } from '@/interfaces/tabs';
import { prepareLocalization, processLocalizationFromBackend } from '@/lib/utils/localization';
import { CatalogStatusEnum } from '../catalogsAuctions/entityCommon';
import { InputSuggestionMultipleItem } from '@/lib/formFactory/inputSuggestionMultiple.interface';
import CurrencyModule from '@/admin/store/currency';
import { getSettingAuction, SettingsSectionInterface } from '@/admin/api/settingAuction';
import { PainterProductivityCategoryInterface } from '@/interfaces/settingsAuctions';
import { getCurrenciesWithoutRuble } from '@/lib/utils/currency';

const MODULE_NAME = 'AuctionLotEntityModule';

export enum LotStatusEnum {
    DRAFT = 'draft',
    READY_TO_PUBLISH = 'ready_to_publish',
    PUBLISHED = 'published',
    CORRESPONDENCE_BIDDING = 'correspondence_bidding',
    ONLINE_AUCTION = 'online_auction',
    STATUS_SOLD = 'sold',
    STATUS_NOT_SOLD = 'not_sold',
    STATUS_POST_AUCTION = 'post_auction',
    WITHDRAWN_FROM_SALE = 'withdrawn_from_sale',
    DELETED = 'deleted',
    STATUS_JUST_WORK = 'just_work',
}

const blockingStatuses = [
    LotStatusEnum.READY_TO_PUBLISH,
    LotStatusEnum.PUBLISHED,
    LotStatusEnum.CORRESPONDENCE_BIDDING,
    LotStatusEnum.ONLINE_AUCTION,
    LotStatusEnum.STATUS_SOLD,
    LotStatusEnum.STATUS_NOT_SOLD,
    LotStatusEnum.STATUS_POST_AUCTION,
    LotStatusEnum.WITHDRAWN_FROM_SALE,
    LotStatusEnum.DELETED,
];

type ModalParams = Record<string, ModalParamsItem>;

interface ModalParamsItem {
    isShow: boolean;
    title: string;
    editor: {
        title: string;
        value: string;
        key: string;
        error: {
            class: string;
            message: string;
        };
    };
}

const pricesEstimateFieldNames = ['pricesEstimateMin', 'pricesEstimateMax'];

const modelPriceFieldsFilterer = (key: string) => {
    return key.indexOf('price') === 0 && key.indexOf('priceBuyNow') === -1 && !pricesEstimateFieldNames.includes(key);
};

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class AuctionLotEntityModule extends EntityBaseModule {
    cardId: number | null = null;
    model: any;
    selectDefault = { id: 0, value: '' };
    sellerType = '';
    modalParams: ModalParams;
    titleReturnData = {
        edit: {
            title: '',
        },
        create: {
            title: 'Добавление лота',
        },
    };
    tabsNav: tabsNavInterface = {};
    requestSort = 'sort[0][id]=name&sort[0][value]=ASC&';
    isAllowRemove = false;
    isAllowEdit = false;
    isAllowWithdraw = false;
    isAllowUnpublish = false;
    isAllowPublish = false;
    painterProductivity = 0;
    painterSettingsCategories: PainterProductivityCategoryInterface[] = [];
    painterSettingsCurrentCategory: PainterProductivityCategoryInterface | null = null;

    constructor(module: AuctionLotEntityModule) {
        super(module);

        const entityModel = new EntityModel();
        this.modalParams = modalParams;
        this.model = entityModel.model;

        this.tabsNav = tabsNav;
    }

    @Mutation
    SET_PAINTER_PRODUCTIVITY(productivity: number) {
        this.painterProductivity = productivity;
    }

    @Mutation
    SET_PAINTER_SETTINGS_CATEGORIES(categories: PainterProductivityCategoryInterface[]) {
        this.painterSettingsCategories = categories;
    }

    @Mutation
    SET_PAINTER_CURRENT_CATEGORY(category: PainterProductivityCategoryInterface) {
        this.painterSettingsCurrentCategory = category;
    }

    @Mutation
    SET_CARD_ID(id: number | null) {
        this.cardId = id;
    }

    @Mutation
    SET_TITLE_RETURN_NAME(name: string) {
        this.titleReturnData.edit.title = name;
    }

    @Mutation
    SET_CATEGORY_SELECT_LIST(categories: SelectItem[]) {
        this.model.categorySelect.list = categories;
    }

    @Mutation
    SET_CATEGORY_SELECT(category: SelectItem) {
        this.model.categorySelect.current = category;
    }

    @Mutation
    SET_CURRENCY_LIST(currencies: SelectItem[]) {
        this.model.currency.list = currencies;
    }

    @Mutation
    SET_CURRENCY(currency: { id: number; value: string }) {
        this.model.currency.current = currency;
    }

    @Mutation
    SET_SELLER_TYPE(sellerType: string) {
        this.sellerType = sellerType;
    }

    @Mutation
    SET_DESCRIPTION(value: string) {
        this.modalParams.description.editor.value = value;
    }

    @Mutation
    SET_EXCHANGE_RATE_TYPE(rateType: string) {
        this.model.exchangeRateType.value = rateType;
    }

    @Mutation
    SET_BID_STEP(value: string | number) {
        this.model.bidStep = value;
    }

    @Mutation
    SET_DATE_START(date: string) {
        this.model.dateTimeStartTrad = date;
    }

    @Mutation
    SET_DATE_FINISH(date: string) {
        this.model.dateTimeFinishTrad = date;
    }

    @Mutation
    SET_IS_RIGHT_TO_FOLLOW(bool: boolean) {
        this.model.isRightToFollow.list[0].checked = bool;
    }

    @Mutation
    SET_SIZES(sizes: string) {
        this.model.sizes.value = sizes;
    }

    @Mutation
    SET_LOT_NUMBER(number: string) {
        this.model.lotNumber.value = number;
    }

    @Mutation
    SET_IS_PRICE_RESERVE(bool: boolean) {
        this.model.isPriceReserve.list[0].checked = bool;
    }

    @Mutation
    SET_IS_POSTAUCTION_SALE(bool: boolean) {
        this.model.isPostauctionSale.list[0].checked = bool;
    }

    @Mutation
    SET_DESCRIPTION_MODAL(bool: boolean) {
        this.modalParams.description.isShow = bool;
    }

    @Mutation
    SET_PRICE_BY_TYPE(params: { type: string; value: string }) {
        if (!this.model[params.type]) {
            return;
        }

        this.model[params.type].value = String(params.value);
    }

    @Mutation
    SET_PRICE_START(value: string) {
        this.model.priceStart.value = value;
    }

    @Mutation
    SET_PRICE_MAX(value: string) {
        this.model.priceMax.value = value;
    }

    @Mutation
    SET_PRICE_MIN(value: string) {
        this.model.priceMin.value = value;
    }

    @Mutation
    SET_PRICE_RESERVE(value: string) {
        this.model.priceReserve.value = value;
    }

    @Mutation
    SET_PRICE_BUY_NOW(value: string) {
        this.model.priceBuyNow.value = value;
    }

    @Mutation
    EMPTY_PRICES(name: string) {
        if (!name) return;

        try {
            this.model[name].value = '';
        } catch (error) {
            console.error(`[EMPTY_PRICES] model field ${name} not found`);
        }
    }

    @Mutation
    SET_PHOTOS(list: ImageInput[]) {
        this.model.photos.list = list;
    }

    @Mutation
    SET_BUY_NOW_PRICE_TYPE(value: string) {
        this.model.buyNowPriceType.value = value;
    }

    @Mutation
    SET_BUY_NOW_PRICE_LIST(list: RadioList) {
        this.model.buyNowPriceType.list = list;
    }

    @Mutation
    SET_COMMISSION_AUCTION_HOUSE(value: number) {
        this.model.commissionAuctionHouse = value;
    }

    @Mutation
    SET_COMMISSION_ACQUIRING(value: number) {
        this.model.commissionAcquiring = value;
    }

    @Mutation
    SET_COMMISSION_FINANCIAL_SERVICES(value: number) {
        this.model.commissionFinancialServices = value;
    }

    @Mutation
    SET_COMMISSION_RIGHT_TO_FOLLOW(value: number) {
        this.model.commissionRightToFollow = value;
    }

    @Mutation
    SET_COMMISSION_SITE(value: number) {
        this.model.commissionSite = value;
    }

    @Mutation
    SET_PRICE_SELL(value: string) {
        this.model.priceSell = value;
    }

    @Mutation
    SET_BIDS_COUNT(value: number) {
        this.model.bidsCount = value;
    }

    @Mutation
    SET_USER_WINNER(user: { id: number; name: '' }) {
        this.model.userWinner = user;
    }

    @Mutation
    SET_STATUS(status: string | null) {
        this.model.status = status;
    }

    @Mutation
    SET_STATUS_NAME(status: string | null) {
        this.model.statusName = status;
    }

    @Mutation
    SET_ORDER_ID(id: number) {
        this.model.orderId = id;
    }

    @Mutation
    SET_IS_ALLOW_REMOVE(bool: boolean) {
        this.isAllowRemove = bool;
    }

    @Mutation
    SET_IS_ALLOW_EDIT(bool: boolean) {
        this.isAllowEdit = bool;
    }

    @Mutation
    SET_IS_ALLOW_WITHDRAW(bool: boolean) {
        this.isAllowWithdraw = bool;
    }

    @Mutation
    SET_IS_HIDE_IMAGE(bool: boolean) {
        this.model.hideImage.list[0].checked = bool;
    }

    @Mutation
    SET_IS_ALLOW_PUBLISH(bool: boolean) {
        this.isAllowPublish = bool;
    }

    @Mutation
    SET_RIGHTS_LIMITED(bool: boolean) {
        this.model.rightsLimited.list[0].checked = bool;
    }

    @Mutation
    SET_IS_HIDE_PRICE(bool: boolean) {
        this.model.hidePrice.list[0].checked = bool;
    }

    @Mutation
    SET_EXCHANGE_RATES_VALUE(params: { id: number; value: string }) {
        this.model.exchangeRates.values[params.id].value = params.value;
    }

    @Mutation
    ADD_EXCHANGE_RATE_FIELD(params: { id: number; code: string; symbol: string; value?: string }) {
        this.model.exchangeRates.values[params.id] = {
            id: params.id,
            code: params.code,
            symbol: params.symbol,
            value: params.value || '0',
        };
    }

    @Mutation
    DELETE_EXCHANGE_RATE_FIELD(id?: number) {
        if (!id) {
            this.model.exchangeRates.values = {};

            return;
        }

        delete this.model.exchangeRates.values[id];
    }

    @Action({ rawError: true })
    updateIsActive(bool: boolean) {
        this.SET_IS_ACTIVE(bool);
    }

    @Action({ rawError: true })
    async initCurrencies() {
        this.SET_CURRENCY_LIST(processListForSelect(getCurrenciesWithoutRuble()));
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({
            type: 'allowedCurrencies',
            list: processListForSuggestionsMultiple(getCurrenciesWithoutRuble()),
        });
    }

    @Action({ rawError: true })
    async initForm(params: { sellerType: string; auctionHouseId?: string; galleryId?: string; painterId?: string }) {
        this.context.commit('SET_SELLER_TYPE', params.sellerType);

        await this.initCurrencies();

        this.updateLotDataByQuery(params);

        if (!this.cardId) {
            this.context.commit('SET_EXCHANGE_RATE_TYPE', this.sellerType === 'auction_house' ? 'indicative' : '');
            this.SET_IS_ALLOW_REMOVE(true);
            this.model.category.selected = [];
        }

        if (params.sellerType === 'auction_house') {
            this.context.commit('SET_IS_POSTAUCTION_SALE', this.cardId ? null : true);
            this.context.commit('SET_BUY_NOW_PRICE_TYPE', 'manual');
        }

        if (params.sellerType === 'painter') {
            const categoriesTree = await getCategoryHierarchyList(
                1,
                generateSort({ id: 'name', value: 'ASC' }),
                generateFilter([{ id: 'forPainter', value: '1' }]),
                999,
            );

            this.SET_CATEGORY_SELECT_LIST(
                processCategoryListForSuggestionsMultiple(categoriesTree, 'name', 'categories'),
            );

            this.updatePainterSettingsCategories();
        }
    }

    @Action({ rawError: true })
    async updatePainterSettingsCategories() {
        const painterSettings = await getSettingAuction('?section=CommonPainter');

        const categoriesSettings = painterSettings.reduce(
            (accum: PainterProductivityCategoryInterface[], current: SettingsSectionInterface) => {
                if (!current.name.match(/categories.\d/)) {
                    return accum;
                }

                const categoryProductivity = +current.name.split('.')[1];
                if (categoryProductivity === this.painterProductivity) {
                    accum.push(...JSON.parse(current.value as string));
                }

                return accum;
            },
            [],
        );

        this.SET_PAINTER_SETTINGS_CATEGORIES(categoriesSettings);
    }

    @Action({ rawError: true })
    async setCatalogInfoById(catalogId: number) {
        try {
            const result = await getCatalogAuctionCard(catalogId);
            if (!result.sellerAuctionHouse) {
                return;
            }

            this.updateSuggestionsSelectedByType({ type: 'auction', selected: { id: result.id, value: result.name } });
            this.updateSuggestionsSelectedByType({
                type: 'auctionHouse',
                selected: {
                    id: result.sellerAuctionHouse.id,
                    value: result.sellerAuctionHouse.name,
                },
            });
            this.SET_IS_POSTAUCTION_SALE(result.isPostauctionSale);
        } catch (error) {
            return error;
        }
    }

    @Action({ rawError: true })
    async updateLotDataByQuery(query: { [key: string]: string | undefined }) {
        if (query.auctionHouseId) {
            const auctionHouse = await getAuctionHouseCard(query.auctionHouseId);
            this.updateSuggestionsSelectedByType({
                type: 'auctionHouse',
                selected: {
                    id: auctionHouse.id,
                    value: auctionHouse.name,
                },
            });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'auctionHouse', disabled: true });
        }

        if (query.galleryId) {
            const gallery = await getGalleryCard(query.galleryId);
            this.updateSuggestionsSelectedByType({
                type: 'sellerGallery',
                selected: {
                    id: gallery.id,
                    value: gallery.name,
                },
            });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'sellerGallery', disabled: true });
        }

        if (query.painterId) {
            const painter = await getPainterCard(query.painterId);
            this.updatePainter({ selected: { id: painter.id, value: painter.name } });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'sellerPainter', disabled: true });
        }
    }

    @Action({ rawError: true })
    async initCardById(lotId: string) {
        try {
            this.updateIsLoading(true);
            const result = await getLotCard(lotId);

            await this.setInfo(result);
            this.updateIsLoading(false);
        } catch (error) {
            this.updateIsLoading(false);
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    async initTitleReturnById(lotId: string) {
        const result = await getLotCard(lotId);

        this.context.commit('SET_TITLE_RETURN_NAME', result.name || '');
    }

    @Action({ rawError: true })
    async setInfo(data: any) {
        if (!data) {
            return;
        }

        this.context.commit('SET_CARD_ID', data.id);
        this.context.commit('SET_TITLE_RETURN_NAME', data.name);
        this.SET_IS_HIDE_IMAGE(data.hideImage);
        this.SET_IS_HIDE_PRICE(data.hidePrice);

        this.context.commit('SET_SORT_ORDER', data.sort ? data.sort : '');
        this.context.commit('SET_LOT_NUMBER', data.number ? data.number : '');

        this.context.commit('SET_DATE_START', data.dateTimeStartTrad ? formatCreatedAt(data.dateTimeStartTrad) : '-');
        this.context.commit(
            'SET_DATE_FINISH',
            data.dateTimeFinishTrad ? formatCreatedAt(data.dateTimeFinishTrad) : '-',
        );
        this.context.commit('SET_DATE_POSTING', data.datetimePosting ? formatCreatedAt(data.datetimePosting) : '-');
        this.context.commit('SET_CREATED_AT', data.createdAt ? formatCreatedAt(data.createdAt) : '-');

        this.context.commit(
            'SET_COMMISSION_AUCTION_HOUSE',
            data.commissionAuctionHouse ? data.commissionAuctionHouse : 0,
        );
        this.context.commit('SET_COMMISSION_ACQUIRING', data.commissionAcquiring ? data.commissionAcquiring : 0);
        this.context.commit(
            'SET_COMMISSION_FINANCIAL_SERVICES',
            data.commissionFinancialServices ? data.commissionFinancialServices : 0,
        );
        this.context.commit(
            'SET_COMMISSION_RIGHT_TO_FOLLOW',
            data.commissionRightToFollow ? data.commissionRightToFollow : 0,
        );
        this.context.commit('SET_COMMISSION_SITE', data.commissionSite ? data.commissionSite : 0);
        this.context.commit('SET_PRICE_START', data.priceStart ? data.priceStart : '0');
        this.context.commit('SET_PRICE_SELL', data.priceSell ? data.priceSell : '0');
        this.context.commit('SET_BIDS_COUNT', data.bidCount);

        this.context.commit('SET_IS_RIGHT_TO_FOLLOW', data.isRightToFollow);
        this.context.commit('SET_IS_PRICE_RESERVE', data.isPriceReserve);
        this.context.commit('SET_IS_POSTAUCTION_SALE', data.auction?.isPostauctionSale);

        this.context.commit('SET_SIZES', data.sizes);

        this.context.commit(
            'SET_CURRENCY',
            data.currency && !data.sellerAuctionHouse?.id
                ? { id: data.currency.id, value: data.currency.name }
                : { id: 0, value: '' },
        );

        if (data.exchangeRateType) {
            this.SET_EXCHANGE_RATE_TYPE(data.exchangeRateType);
        }

        this.context.commit('SET_BID_STEP', data.bidStep ? data.bidStep : '-');

        this.context.commit('SET_ORDER_ID', data.orders?.length > 0 ? data.orders[0].id : 0);

        const statuses: { id: string; name: string }[] = await getLotsStatuses();
        const result = statuses.filter((item) => item.id === data.status)[0];
        this.SET_STATUS_NAME(result ? result.name : null);

        this.updateStatus(data);

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'name',
            data: processLocalizationFromBackend(data),
        });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'description',
            data: processLocalizationFromBackend(data, 'description'),
        });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'year',
            data: processLocalizationFromBackend(data, 'year'),
        });

        this.SET_LOCALIZATION_BY_FIELD({
            fieldName: 'material',
            data: processLocalizationFromBackend(data, 'material'),
        });

        this.SET_MODEL_FIELD_VALUE({ field: 'slug', value: data.slug || '' });

        if (data.status && data.sellerAuctionHouse && blockingStatuses[0].includes(data.status)) {
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'auctionHouse', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'auction', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'isPriceReserve', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'isPostauctionSale', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'isRightToFollow', disabled: true });


            Object.keys(this.model)
                .filter(modelPriceFieldsFilterer)
                .forEach((key) => {
                    this.SET_FIELD_DISABLED_BY_TYPE({ type: key, disabled: true });
                });
        }

        if (data.status && data.sellerAuctionHouse && blockingStatuses[1].includes(data.status)) {
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'auctionHouse', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'auction', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'isPriceReserve', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'isPostauctionSale', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'buyNowPriceType', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'isRightToFollow', disabled: true });

            Object.keys(this.model)
                .filter((key) => key.indexOf('price') === 0)
                .forEach((key) => {
                    this.SET_FIELD_DISABLED_BY_TYPE({ type: key, disabled: true });
                });
        }

        if (
            data.status === 'published' ||
            data.status === 'correspondence_bidding' ||
            data.status === 'online_auction' ||
            data.status === 'sold' ||
            data.status === 'not_sold' ||
            data.status === 'post_auction' ||
            data.status === 'withdrawn_from_sale' ||
            data.status === 'deleted'
        ) {
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'isPriceReserve', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'priceStart', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'priceReserve', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'isRightToFollow', disabled: true });
        } else {
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'isPriceReserve', disabled: false });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'priceStart', disabled: false });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'priceReserve', disabled: false });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'isRightToFollow', disabled: false });
        }

        if (data.userWinner) {
            const user = data.userWinner;
            const userName = [user.firstName, user.middleName, user.lastName]
                .filter((item) => {
                    item;
                })
                .join(' ');
            this.context.commit('SET_USER_WINNER', { id: user.id, name: userName });
        }

        for (const key of Object.keys(this.model)) {
            if (key.indexOf('price') !== 0) {
                continue;
            }

            if (!data[key]) {
                continue;
            }

            if (this.model[key].value === undefined) {
                continue;
            }

            this.SET_PRICE_BY_TYPE({ type: key, value: data[key] });
        }

        if (data.sellerAuctionHouse) {
            this.updateSuggestionsMultipleHintsListByType({
                list: processListForSuggestionsMultiple(data.categories, 'name', true),
                type: 'category',
            });
            this.updateSuggestionsSelectedByType({
                selected: data.sellerAuctionHouse?.id
                    ? { id: data.sellerAuctionHouse.id, value: data.sellerAuctionHouse.name }
                    : this.selectDefault,
                type: 'auctionHouse',
            });

            this.updateSuggestionsSelectedByType({
                selected: data.auction?.id ? { id: data.auction.id, value: data.auction.name } : this.selectDefault,
                type: 'auction',
            });

            if (data.buyNowPriceType) {
                this.context.commit('SET_BUY_NOW_PRICE_TYPE', data.buyNowPriceType);
            }
        }

        if (data.sellerGallery) {
            this.updateSuggestionsMultipleHintsListByType({
                list: processListForSuggestionsMultiple(data.categories, 'name', true),
                type: 'category',
            });
            this.updateSuggestionsSelectedByType({
                selected: data.sellerGallery?.id
                    ? { id: data.sellerGallery.id, value: data.sellerGallery.name }
                    : this.selectDefault,
                type: 'sellerGallery',
            });
            this.context.commit('SET_SELLER_TYPE', 'gallery');
        }

        if (data.sellerPainter) {
            const painterBlockingStatuses = ['sold', 'not_sold'];
            const category = data.categories?.length ? processListForSelect(data.categories)[0] : this.selectDefault;

            await this.updatePainter({
                selected: { id: data.sellerPainter.id, value: data.sellerPainter.name },
                category,
            });

            const matchedCategory = await this.getSettingsCategory(category);

            if (matchedCategory) {
                this.SET_PAINTER_CURRENT_CATEGORY(matchedCategory);
            }

            this.context.commit('SET_SELLER_TYPE', 'painter');

            const fieldsBlocker = data.published || painterBlockingStatuses.includes(data.status);

            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'sellerPainter', disabled: fieldsBlocker });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'categorySelect', disabled: fieldsBlocker });

            this.SET_MODEL_FIELD_VALUE({ field: 'painterStartPrice', value: data.priceStart || '0' });
            this.SET_MODEL_FIELD_VALUE({ field: 'painterMaxPrice', value: data.priceMax || '0' });
            this.SET_MODEL_FIELD_VALUE({ field: 'painterBuyNowPrice', value: data.priceBuyNow || '0' });
        }

        if ((data.sellerGallery || data.sellerPainter) && data.status === LotStatusEnum.PUBLISHED) {
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'category', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'sellerGallery', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'sellerPainter', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'hidePrice', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'hideImage', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'currency', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'exchangeRateType', disabled: true });
            this.SET_FIELD_DISABLED_BY_TYPE({ type: 'priceBuyNow', disabled: true });
        }

        if (data.photos && data.photos.length > 0) {
            const result = data.photos.map((item: string, index: number) => {
                const key = `photo_${index}`;
                const photo = new ImageInputEntity(key);
                photo.setValue(item);

                return photo.entity[key];
            });

            this.context.commit('SET_PHOTOS', result);
        }

        if (data.authorString?.length) {
            this.SET_SUGGESTIONS_VALUE_BY_TYPE({ type: 'author', value: data.authorString });
        }

        if (data.author) {
            this.context.dispatch('updateSuggestionsSelectedByType', {
                type: 'author',
                selected: { id: data.author.id, value: data.author.name },
            });
        }

        if (data.exchangeRates?.length && this.sellerType !== 'auction_house') {
            data.exchangeRates.forEach(async (rate: { currency: string; course: number }) => {
                const currencies = await CurrencyModule.getCurrencies();
                const matchedCurrency = currencies.find((currency) => currency.code === rate.currency);

                if (!matchedCurrency) {
                    return;
                }

                this.ADD_EXCHANGE_RATE_FIELD({
                    id: matchedCurrency.id,
                    code: matchedCurrency.code,
                    symbol: matchedCurrency.symbol,
                    value: String(rate.course),
                });
            });
        }

        if (data.pricesEstimate) {
            this.SET_MODEL_FIELD_VALUE({ field: 'pricesEstimateMin', value: data.pricesEstimate.min });
            this.SET_MODEL_FIELD_VALUE({ field: 'pricesEstimateMax', value: data.pricesEstimate.max });
        }

        if (data.centralBankCurrencies?.length) {
            const selectedCurrencies = CurrencyModule.currencies.filter(
                (currency) => data.centralBankCurrencies.includes(currency.code) && currency.code !== 'RUB',
            );

            const updatedCurrencies = this.model.allowedCurrencies.hintsList.map(
                (currency: InputSuggestionMultipleItem) => {
                    return {
                        ...currency,
                        selected: selectedCurrencies.find((cur) => cur.id === currency.id),
                    };
                },
            );

            this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({
                type: 'allowedCurrencies',
                list: updatedCurrencies,
            });
        }

        this.SET_PAINTER_PRODUCTIVITY((data.sellerPainter || data.author)?.productivity);
    }

    @Action({ rawError: true })
    updateStatus(data: any) {
        const status = data.status;
        this.SET_STATUS(status);

        if ([LotStatusEnum.DRAFT, LotStatusEnum.STATUS_JUST_WORK].includes(status)) {
            this.SET_IS_ALLOW_REMOVE(true);
            this.SET_IS_ALLOW_EDIT(true);
            this.SET_IS_ALLOW_WITHDRAW(false);
            this.SET_IS_ALLOW_PUBLISH(false);
        } else if (status === LotStatusEnum.READY_TO_PUBLISH) {
            this.SET_IS_ALLOW_REMOVE(true);
            this.SET_IS_ALLOW_EDIT(true);
            this.SET_IS_ALLOW_WITHDRAW(false);
            this.SET_IS_ALLOW_PUBLISH(data.sellerGallery || data.sellerPainter);
        } else if (status === LotStatusEnum.PUBLISHED) {
            this.SET_IS_ALLOW_REMOVE(false);
            this.SET_IS_ALLOW_EDIT(true);
            this.SET_IS_ALLOW_WITHDRAW(true);
            this.SET_IS_ALLOW_PUBLISH(false);
        } else if (status === LotStatusEnum.CORRESPONDENCE_BIDDING) {
            this.SET_IS_ALLOW_REMOVE(false);
            this.SET_IS_ALLOW_EDIT(true);
            this.SET_IS_ALLOW_WITHDRAW(true);
            this.SET_IS_ALLOW_PUBLISH(false);
        } else if (
            [
                LotStatusEnum.ONLINE_AUCTION,
                LotStatusEnum.STATUS_SOLD,
                LotStatusEnum.STATUS_NOT_SOLD,
                LotStatusEnum.WITHDRAWN_FROM_SALE,
                LotStatusEnum.DELETED,
            ].includes(status)
        ) {
            this.SET_IS_ALLOW_REMOVE(false);
            this.SET_IS_ALLOW_EDIT(false);
            this.SET_IS_ALLOW_WITHDRAW(false);
            this.SET_IS_ALLOW_PUBLISH(false);
        } else if (status === LotStatusEnum.STATUS_POST_AUCTION) {
            this.SET_IS_ALLOW_REMOVE(false);
            this.SET_IS_ALLOW_EDIT(false);
            this.SET_IS_ALLOW_WITHDRAW(true);
            this.SET_IS_ALLOW_PUBLISH(false);
        } else {
            this.SET_IS_ALLOW_REMOVE(false);
            this.SET_IS_ALLOW_EDIT(false);
            this.SET_IS_ALLOW_WITHDRAW(false);
            this.SET_IS_ALLOW_PUBLISH(false);
        }
    }

    @Action({ rawError: true })
    fillSettingsPrices(category: PainterProductivityCategoryInterface) {
        this.SET_PAINTER_CURRENT_CATEGORY(category);

        this.SET_MODEL_FIELD_VALUE({ field: 'painterStartPrice', value: String(category.startPrice) });
        this.SET_MODEL_FIELD_VALUE({ field: 'painterMaxPrice', value: String(category.maxPrice) });
        this.SET_MODEL_FIELD_VALUE({ field: 'painterBuyNowPrice', value: String(category.priceBuyNow) });
    }

    @Action({ rawError: true })
    getSettingsCategory(selected: SelectItem): PainterProductivityCategoryInterface | void {
        let matchedSettingsCategory = this.painterSettingsCategories.find(
            (category) => category.category.id === selected.id,
        );

        if (matchedSettingsCategory) {
            return matchedSettingsCategory;
        }

        const fullCategories = this.painterSettingsCategories.reduce(
            (accum: HierarchyCategorySelectItem[], current: PainterProductivityCategoryInterface) => {
                const fullCategory = getFullCategory(this.model.categorySelect.list, current.category.id);

                if (!fullCategory) {
                    return accum;
                }

                if (!accum) {
                    accum = [];
                }

                accum.push(fullCategory);

                return accum;
            },
            [],
        );

        if (!fullCategories?.length) {
            return;
        }

        const parentCategories = fullCategories.reduce((accum: any[], current) => {
            const result = getContainsCategory([current], selected);

            if (result.contains) {
                accum.push({ ...current, lvl: result.lvl });
            }

            return accum;
        }, []);

        parentCategories.sort((a, b) => a.lvl - b.lvl);

        matchedSettingsCategory = this.painterSettingsCategories.find(
            (category) => category.category?.id === parentCategories[0]?.id,
        );

        return matchedSettingsCategory;
    }

    @Action({ rawError: true })
    async updateCategorySelect(selected: SelectItem) {
        this.SET_CATEGORY_SELECT(selected);

        const matchedCategory = await this.getSettingsCategory(selected);

        if (!matchedCategory) {
            return;
        }

        this.fillSettingsPrices(matchedCategory);
    }

    @Action({ rawError: true })
    async searchCategory(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: search, type: 'category' });
        
        const selectedId = this.model.category.selected.map((item: any) => {
            return item?.id || item;
        });

        const filter = search ? generateFilter([{ id: 'name', value: capitaize(search) }]) : '';
        const categoriesTree = await getCategoryHierarchyList(1, this.requestSort, filter, 50);


        // const categories = processCategoryListForSuggestionsMultiple(categoriesTree, 'name', 'categories');
        // const selectedItems = getTreeCategoryListSelected(this.model.category.hintsList);

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({
            list: getTreeCategoryFullListSelected(categoriesTree, selectedId),
            type: 'category',
        });
    }

    @Action({ rawError: true })
    async searchAuctionHouse(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: search, type: 'auctionHouse' });

        const table = await getAuctionHouseList(
            1,
            this.requestSort,
            `&filters[0][id]=name&filters[0][value]=${search}`,
            50,
        );
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ list: await processListForSelect(table.rows), type: 'auctionHouse' });
    }

    @Action({ rawError: true })
    updateSelectedAuctionHouse(selected: InputSuggestionItem) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: selected.value, type: 'auctionHouse' });

        if (selected.id === this.model.auctionHouse.current.id) {
            return;
        }

        this.context.commit('RESET_ERROR_BY_FIELD', 'auctionHouse');
        this.SET_SUGGESTIONS_SELECTED_BY_TYPE({ selected, type: 'auctionHouse' });

        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: '', type: 'auction' });
        this.SET_SUGGESTIONS_SELECTED_BY_TYPE({ selected: this.selectDefault, type: 'auction' });
    }

    @Action({ rawError: true })
    async searchCatalogAuction(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: search, type: 'auction' });

        const filterItems = [
            { id: 'name', value: search },
            { id: 'status', value: CatalogStatusEnum.DRAFT },
        ];

        if (this.model.auctionHouse?.current?.id) {
            filterItems.push({ id: 'sellerAuctionHouseName', value: this.model.auctionHouse.current.value });
        }

        const table = await getCatalogAuctionList(1, this.requestSort, generateFilter(filterItems), 50);
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ list: await processListForSelect(table.rows), type: 'auction' });
    }

    @Action({ rawError: true })
    async updateLotDataByCatalogId(id: number) {
        const catalog = await getCatalogAuctionCard(id);

        this.SET_IS_POSTAUCTION_SALE(catalog.isPostauctionSale);
        this.updateCurrency({ id: catalog.currency.id, value: catalog.currency.name });

        if (!catalog.priceStart) {
            return;
        }

        this.context.commit('SET_PRICE_START', catalog.priceStart);
    }

    @Action({ rawError: true })
    async searchPainter(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: search, type: 'sellerPainter' });

        const table = await getPainterList(1, this.requestSort, `&filters[0][id]=name&filters[0][value]=${search}`, 50);
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({
            list: await processListForSelect(table.rows),
            type: 'sellerPainter',
        });
    }

    @Action({ rawError: true })
    async updatePainter(params: { selected: InputSuggestionItem; category?: SelectItem }) {
        this.updateSuggestionsSelectedByType({ type: 'sellerPainter', selected: params.selected });
        if (params.category) {
            this.SET_CATEGORY_SELECT(params.category ?? this.selectDefault);
        }
        this.RESET_ERROR_BY_FIELD('categorySelect');
    }

    @Action({ rawError: true })
    async searchGallery(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: search, type: 'sellerGallery' });

        const table = await getGalleryList(
            1,
            this.requestSort,
            `&filters[0][id]=name&filters[0][value]=${search.replaceAll('#', '')}`,
            50,
        );
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({
            list: await processListForSelect(table.rows),
            type: 'sellerGallery',
        });
    }

    @Action({ rawError: true })
    updateDescription(value: string) {
        this.context.commit('SET_DESCRIPTION', value);
    }

    @Action({ rawError: true })
    updateSortOrder(value: string) {
        this.context.commit('SET_SORT_ORDER', value);
    }

    @Action({ rawError: true })
    updateLotNumber(value: string) {
        this.context.commit('RESET_ERROR_BY_FIELD', 'lotNumber');
        this.context.commit('SET_LOT_NUMBER', value);
    }

    @Action({ rawError: true })
    updateIsRightToFollow(bool: boolean) {
        this.context.commit('SET_IS_RIGHT_TO_FOLLOW', bool);
    }

    @Action({ rawError: true })
    updateCurrency(currency: { id: number; value: string }) {
        this.RESET_ERROR_BY_FIELD('currency');
        this.context.commit('SET_CURRENCY', currency);
    }

    @Action({ rawError: true })
    async updateExchangeRateType(value: string) {
        this.context.commit('RESET_ERROR_BY_FIELD', 'exchangeRateType');
        await this.context.commit('SET_EXCHANGE_RATE_TYPE', value);
        // this.hidePrices();
    }

    @Action({ rawError: true })
    async updateBuyNowPriceType(value: string) {
        if (value === 'start') {
            this.SET_PRICE_BUY_NOW(this.model.priceStart.value);
        }

        if (value === 'reserve') {
            this.SET_IS_PRICE_RESERVE(true);
            this.SET_PRICE_BUY_NOW(this.model.priceReserve.value);
        }

        if (value === 'manual') {
            this.context.commit('EMPTY_PRICES', 'priceBuyNow');
        }

        this.context.commit('SET_BUY_NOW_PRICE_TYPE', value);
    }

    @Action({ rawError: true })
    async updateIsPriceReserve(bool: boolean) {
        this.context.commit('SET_IS_PRICE_RESERVE', bool);
        this.RESET_ERROR_BY_FIELD('priceReserve');

        if (!bool && this.model.priceStart.value && this.model.priceStart.error.class.length) {
            this.RESET_ERROR_BY_FIELD('priceStart');
        }
    }

    @Action({ rawError: true })
    async updateIsPostauctionSale(bool: boolean) {
        this.SET_IS_POSTAUCTION_SALE(bool);
        this.RESET_ERROR_BY_FIELD('priceBuyNow');
    }

    @Action({ rawError: true })
    updateSizes(value: string) {
        this.context.commit('SET_SIZES', value);
    }

    @Action({ rawError: true })
    async updatePriceStart(value: string) {
        if (this.model.isPriceReserve.list[0].checked) {
            const result = await this.validatePriceReserve({ priceStart: { ...this.model.priceStart, value } });
            this.SET_PRICE_RESERVE(result.priceReserve.value);
            this.SET_PRICE_START(result.priceStart.value);
        }

        if (!this.model.isPriceReserve.list[0].checked) {
            this.SET_PRICE_START(value);
        }

        if (this.model.buyNowPriceType.value === 'start') {
            this.SET_PRICE_BUY_NOW(value);
        }
    }

    @Action({ rawError: true })
    updatePriceMin(value: string) {
        this.SET_PRICE_MIN(value);
    }

    @Action({ rawError: true })
    updateIsHideImage(bool: boolean) {
        this.SET_IS_HIDE_IMAGE(bool);
    }

    @Action({ rawError: true })
    updateIsHidePrice(bool: boolean) {
        this.SET_IS_HIDE_PRICE(bool);
    }

    @Action({ rawError: true })
    async updatePriceReserve(value: string) {
        const result = await this.validatePriceReserve({ priceReserve: { ...this.model.priceReserve, value } });
        this.SET_PRICE_RESERVE(result.priceReserve.value);
        this.SET_PRICE_START(result.priceStart.value);

        if (this.model.buyNowPriceType.value === 'reserve') {
            this.SET_PRICE_BUY_NOW(value);
        }
    }

    @Action({ rawError: true })
    validatePriceReserve(params?: { priceReserve?: Input; priceStart?: Input }) {
        const errorClass = 'cp-error';
        let priceStart: Input = params?.priceStart || this.model.priceStart;
        let priceReserve: Input = params?.priceReserve || this.model.priceReserve;

        if (+priceReserve.value < +priceStart.value) {
            priceStart = { ...priceStart, error: { class: errorClass, message: '' } };
            priceReserve = { ...priceStart, error: { class: errorClass, message: priceStart.validation.value } };
        }

        return {
            priceStart,
            priceReserve,
        };
    }

    @Action({ rawError: true })
    updatePriceBuyNow(value: string) {
        this.context.commit('SET_PRICE_BUY_NOW', value);
    }

    @Action({ rawError: true })
    async searchAuthor(search: string) {
        this.updateSuggestionsSelectedByType({ type: 'author', selected: { id: 0, value: search } });

        const table = await getPainterList(
            1,
            await generateSort({ id: 'name', value: 'ASC' }),
            `&filters[0][id]=name&filters[0][value]=${search}`,
            50,
        );
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'author', list: processListForSelect(table.rows) });
    }

    @Action({ rawError: true })
    async updatePhotoFileById(params: { id: string; file: File, cropp: boolean }) {
        const result: ImageInput[] = [];

        for (const item of this.model.photos.list) {
            if (item.key === params.id) {
                if (!params.cropp) {
                    item.value = '';
                }

                item.file = params.file;
                const preview = typeof params.file === 'string' ? params.file : await imageFileToBase64(params.file);
                item.preview = typeof preview === 'string' ? preview : '';
            }

            result.push(item);
        }

        this.context.commit('SET_PHOTOS', result);
    }

    @Action({ rawError: true })
    async addImages(files: File[]) {
        this.RESET_ERROR_BY_FIELD('photos');

        const filesSorted = Array.from(files).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

        const newImages: File[] = [];

        for (const index in filesSorted) {
            const file = filesSorted[index];
            const preview = await imageFileToBase64(file);
            const imageEntity = new ImageInputEntity(`photo_${this.model.photos.list.length + index}`);
            const image = imageEntity.entity[imageEntity.entityName];

            image.file = file;
            image.preview = typeof preview === 'string' ? preview : '';

            newImages.push(image);
        }

        this.context.commit('SET_PHOTOS', [...this.model.photos.list, ...newImages]);
    }

    @Action({ rawError: true })
    updateMainPhoto(id: string) {
        let photos = [...this.model.photos.list];
        const mainPhotoIndex = Number(id.split('_')[1]);
        const mainPhoto = photos.splice(mainPhotoIndex, 1)[0];
        photos = [mainPhoto, ...photos];

        photos.forEach((photo, index) => {
            photo.key = `photo_${index}`;
        });

        this.SET_PHOTOS(photos);
    }

    @Action({ rawError: true })
    updateImageSort(params: { moveFrom: number; moveTo: number }) {
        const moveFrom = params.moveFrom;
        const moveTo = params.moveTo;

        const imagesSorted = this.model.photos.list.map((image: ImageInput, index: number) => {
            if (index === moveFrom) {
                return { ...image, key: `photo_${moveTo}` };
            }

            if (moveFrom > moveTo && index >= moveTo && index < moveFrom) {
                return { ...image, key: `photo_${index + 1}` };
            }

            if (moveFrom < moveTo && index > moveFrom && index <= moveTo) {
                return { ...image, key: `photo_${index - 1}` };
            }

            return { ...image };
        });

        imagesSorted.sort((a: ImageInput, b: ImageInput) => {
            return Number(a.key.split('_')[1]) < Number(b.key.split('_')[1]) ? -1 : 1;
        });

        this.SET_PHOTOS(imagesSorted);
    }

    @Action({ rawError: true })
    removeImageById(id: string) {
        const photos = this.model.photos.list.filter((item: ImageInput) => item.key !== id);
        this.context.commit('SET_PHOTOS', photos);
    }

    @Action({ rawError: true })
    toggleModal(bool: boolean) {
        this.context.commit('SET_DESCRIPTION_MODAL', bool);
    }

    @Action({ rawError: true })
    updateExchangeRateField(params: { id: number; value: string }) {
        this.SET_EXCHANGE_RATES_VALUE(params);
    }

    @Action({ rawError: true })
    addExchangeRateField(params: { id: number; code: string; symbol: string }) {
        this.ADD_EXCHANGE_RATE_FIELD(params);
    }

    @Action({ rawError: true })
    deleteExchangeRateField(id: number) {
        this.DELETE_EXCHANGE_RATE_FIELD(id);
    }

    @Action({ rawError: true })
    updateAllowedCurrencies(params: InputSuggestionMultipleItem) {
        const hintsListUpdated = this.model.allowedCurrencies.hintsList.map((currency: InputSuggestionMultipleItem) => {
            if (currency.id === params.id) {
                currency.selected = params.selected;
            }

            return currency;
        });

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'allowedCurrencies', list: hintsListUpdated });
    }

    @Action({ rawError: true })
    async validate() {
        let errorCounter = 0;

        if (this.sellerType === 'painter') {
            errorCounter += [
                this.model.painterStartPrice.error.message,
                this.model.painterMaxPrice.error.message,
                this.model.painterBuyNowPrice.error.message,
            ].filter((message) => message).length;
        }

        // this.setValidateState({ index: 'name', field: this.model.name });
        // this.setValidateImageCollection({ index: 'photos', field: this.model.photos });

        // if (this.sellerType === 'painter') {
        //     this.setValidateSelect({
        //         index: 'categorySelect',
        //         field: this.model['categorySelect'],
        //     });
        // }

        // if (this.sellerType !== 'painter') {
        //     this.setValidateMultiSuggestions({ index: 'category', field: this.model.category });
        // }

        // if (this.sellerType === 'auction_house') {
        //     this.setValidateSelect({ index: 'auction', field: this.model.auction });
        //     this.setValidateSelect({ index: 'auctionHouse', field: this.model.auctionHouse });
        //     this.setValidateState({ index: 'lotNumber', field: this.model.lotNumber });

        //     const pricesStart = await getValidatedPricesList(this.model.pricesStart);
        //     this.SET_PRICES_START(pricesStart.prices);
        //     errorCounter += +pricesStart.errorsCount;
        // }

        // if (this.sellerType === 'painter') {
        //     this.setValidateSelect({ index: 'sellerPainter', field: this.model.sellerPainter });
        //     this.setValidateSelect({ index: 'currency', field: this.model.currency });
        //     this.setValidateRadio({ index: 'exchangeRateType', field: this.model.exchangeRateType });
        // }

        // if (this.sellerType === 'gallery') {
        //     this.setValidateSelect({ index: 'sellerGallery', field: this.model.sellerGallery });
        //     this.setValidateSelect({ index: 'currency', field: this.model.currency });
        //     this.setValidateRadio({ index: 'exchangeRateType', field: this.model.exchangeRateType });
        // }

        // let pricesReserve: { prices: Input[]; errorsCount: number } = { prices: [], errorsCount: 0 };
        // let pricesBuyNow: { prices: Input[]; errorsCount: number } = { prices: [], errorsCount: 0 };

        if (this.model.isPriceReserve.list[0].checked) {
            const result = await this.validatePriceReserve();

            this.setValidateState({ index: 'priceReserve', field: this.model.priceReserve });

            this.SET_PRICE_RESERVE(result.priceReserve.value);
            this.SET_PRICE_START(result.priceStart.value);

            errorCounter += [result.priceReserve.error.message, result.priceStart.error.message].filter(
                (message) => message,
            ).length;
        }

        // if (
        //     (this.sellerType === 'auction_house' &&
        //         this.model.isPostauctionSale.list[0].checked &&
        //         this.model.buyNowPriceType.value === 'manual') ||
        //     this.sellerType === 'gallery'
        // ) {
        //     pricesBuyNow = await getValidatedPricesList(this.model.pricesBuyNow);
        //     errorCounter += pricesBuyNow.errorsCount;
        //     this.SET_PRICES_BUY_NOW(pricesBuyNow.prices);
        // }

        // const requiredFieldsNames: string[] = Object.entries(this.model)
        //     .filter((entry: any) => entry[1] != null && entry[1].required === true)
        //     .map((entry: any) => entry[0]);

        // if (this.sellerType === 'auction_house') {
        //     requiredFieldsNames.push('lotNumber');
        // }

        // errorCounter += requiredFieldsNames.filter((field) => this.model[field].error.class === 'cp-error').length;

        return errorCounter > 0 ? true : false;
    }

    @Action({ rawError: true })
    async prepareRequestData(publish?: boolean) {
        const isSellerPainter = this.sellerType === 'painter';
        let data: Record<string, string | number> = {
            name: this.model.name.localization.ru || '',
            hideImage: this.model.hideImage.list[0].checked ? 1 : 0,
            hidePrice: this.model.hidePrice.list[0].checked ? 1 : 0,
        };

        if (this.model.slug.value) {
            data.slug = this.model.slug.value;
        }

        if (publish) {
            data['publish'] = 1;
        }

        data = {
            ...data,
            ...prepareLocalization(this.model.name),
            ...prepareLocalization(this.model.description, 'description'),
            ...prepareLocalization(this.model.material, 'material'),
            ...prepareLocalization(this.model.year, 'year'),
        };

        if (this.model.material.localization.ru) {
            data['material'] = this.model.material.localization.ru;
        }

        if (this.model.year.localization.ru) {
            data['year'] = this.model.year.localization.ru;
        }

        if (this.model.description.localization.ru) {
            data['description'] = this.model.description.localization.ru;
        }

        if (isSellerPainter) {
            data['categories[0]'] = this.model['categorySelect'].current.id;
        }

        if (!isSellerPainter) {
            this.model.category.selected.forEach(
                (category: number | { id: number; selected: boolean; value: string }, index: number) => {
                    data[`categories[${index}]`] = typeof category === 'number' ? category : category.id;
                },
            );
        }

        if (this.model.sort.value) {
            data['sort'] = this.model.sort.value;
        }

        data['isRightToFollow'] = this.model.isRightToFollow.list[0].checked ? 1 : 0;
        data['isPriceReserve'] = this.model.isPriceReserve.list[0].checked ? 1 : 0;

        if (this.model.sizes.value) {
            data['sizes'] = this.model.sizes.value;
        }

        const isPostauctionSale = this.model.isPostauctionSale.list[0].checked;

        if (this.sellerType === 'auction_house') {
            data['isPostauctionSale'] = isPostauctionSale ? 1 : 0;
        }

        if (this.model.currency.current?.id && this.sellerType !== 'auction_house') {
            data['currency'] = this.model.currency.current.id;
        }

        if (this.model.exchangeRateType.value) {
            data['exchangeRateType'] = this.model.exchangeRateType.value;
        }

        if (this.model.auction.current.id) {
            data['auction'] = this.model.auction.current.id;
        }

        if (this.model.auctionHouse.current.id) {
            data['sellerAuctionHouse'] = this.model.auctionHouse.current.id;
        }

        if (this.model.sellerGallery.current.id) {
            data['sellerGallery'] = this.model.sellerGallery.current.id;
        }

        if (this.model.sellerPainter.current.id) {
            data['sellerPainter'] = this.model.sellerPainter.current.id;
        }

        if (this.model.author.current.id) {
            data['author'] = this.model.author.current.id;
        }

        if (this.model.author.current.id === 0 && this.model.author.value.length) {
            data['authorString'] = this.model.author.value;
        }

        if (this.model.lotNumber.value) {
            data['number'] = this.model.lotNumber.value;
        }

        if (!isSellerPainter) {
            if (this.model.priceStart.value) {
                data.priceStart = this.model.priceStart.value;
            }

            if (this.model.priceReserve.value) {
                data.priceReserve = this.model.priceReserve.value;
            }

            if (this.model.priceBuyNow.value) {
                data.priceBuyNow = this.model.priceBuyNow.value;
            }

            if (this.model.buyNowPriceType.value) {
                data.buyNowPriceType = this.model.buyNowPriceType.value;
            }
        }

        if (isSellerPainter) {
            if (this.model.painterStartPrice.value) {
                data['priceStart'] = this.model.painterStartPrice.value;
            }

            if (this.model.painterMaxPrice.value) {
                data['priceMax'] = this.model.painterMaxPrice.value;
            }

            if (this.model.painterBuyNowPrice.value) {
                data['priceBuyNow'] = this.model.painterBuyNowPrice.value;
            }
        }

        if (this.model.photos.list.length > 0) {
            const preparedPhotos = this.model.photos.list.reduce(
                (result: { [key: string]: any }, item: ImageInput, index: number) => {
                    if (!item.file && !item.value) return result;

                    if (item.file) {
                        result[`photos[${index}]`] = item.file;

                        return result;
                    }
                    if (item.value) {
                        result[`photos[${index}]`] = item.value;

                        return result;
                    }
                },
                {},
            );

            data = { ...data, ...preparedPhotos };
        }

        const exchangeRatesValues = Object.values(this.model.exchangeRates.values);
        if (this.model.exchangeRateType.value === 'indicative' && Object.keys(this.model.exchangeRates.values).length) {
            exchangeRatesValues.forEach((rate: any, index) => {
                if (!+rate.value) {
                    return;
                }

                data[`exchangeRates[${index}][currency]`] = rate.code;
                data[`exchangeRates[${index}][course]`] = rate.value;
            });
        }

        if (this.sellerType !== 'auction_house') {
            const allowedCurrencies = this.model.allowedCurrencies.hintsList.filter(
                (currency: any) => currency.selected,
            );
            if (this.model.exchangeRateType.value === 'central_bank' && allowedCurrencies.length) {
                allowedCurrencies.forEach(async (currency: any, index: number) => {
                    const currencies = await CurrencyModule.getCurrencies();
                    const matchedBackendCurrency = currencies.find(
                        (backendCurrency) => backendCurrency.id === currency.id,
                    );

                    if (!matchedBackendCurrency) {
                        return;
                    }

                    data[`allowedCentralBankCurrencies[${index}]`] = matchedBackendCurrency.code;
                });
            }
        }

        if (this.model.pricesEstimateMin.value) {
            data['pricesEstimate[min]'] = this.model.pricesEstimateMin.value;
        }

        if (this.model.pricesEstimateMax.value) {
            data['pricesEstimate[max]'] = this.model.pricesEstimateMax.value;
        }

        return data;
    }

    @Action({ rawError: true })
    async sendPublish() {
        try {
            SiteModule.SET_IS_BLOCK(true);

            await sendLotPublish(String(this.cardId));
            await this.initCardById(String(this.cardId));

            showMessage('Лот опубликован');

            SiteModule.SET_IS_BLOCK(false);
        } catch (error) {
            SiteModule.SET_IS_BLOCK(false);
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    async sendUnpublish() {
        try {
            SiteModule.SET_IS_BLOCK(true);
            await updateLotsStatus({
                lots: String(this.cardId),
                active: this.model.isActive.list[0].checked,
                published: '0',
            });

            SiteModule.SET_IS_BLOCK(false);

            showMessage('Лот снят с публикации');
        } catch (error) {
            SiteModule.SET_IS_BLOCK(false);
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    async sendEdit(publish?: boolean) {
        const isError = await this.validate();

        if (isError) {
            showError('Заполните обязательные поля');

            return;
        }

        if (this.cardId === null) {
            return;
        }

        SiteModule.SET_IS_BLOCK(true);

        try {
            const data = await this.prepareRequestData(publish);
            const result = await editLotCard(this.cardId, data);

            if (result.id) {
                this.setInfo(result);
            }

            SiteModule.SET_IS_BLOCK(false);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            SiteModule.SET_IS_BLOCK(false);
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async sendAdd(params: { redirect?: { name: string; params?: { catalogId: string } }; publish?: boolean }) {
        const isError = await this.validate();

        if (isError) {
            showError('Заполните обязательные поля');

            return;
        }

        try {
            SiteModule.SET_IS_BLOCK(true);
            const data = await this.prepareRequestData(params.publish);
            const result: any = await createLotCard(data);

            SiteModule.SET_IS_BLOCK(false);

            const redirect = params.redirect?.params
                ? params.redirect
                : { name: 'lot', params: { lotId: result.data.result.item.id } };

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            SiteModule.SET_IS_BLOCK(false);
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    async sendWithdraw(id: string) {
        try {
            SiteModule.SET_IS_BLOCK(true);

            await withdrawLotFromSales(id);
            this.initCardById(id);

            SiteModule.SET_IS_BLOCK(false);
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
            SiteModule.SET_IS_BLOCK(false);
        }
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_IS_LOADING', true);

        this.context.commit('SET_CARD_ID', null);

        this.context.commit('SET_DESCRIPTION', '');
        this.context.commit('SET_SORT_ORDER', '');
        this.context.commit('SET_LOT_NUMBER', '');

        this.context.commit('SET_DATE_START', '');
        this.context.commit('SET_DATE_FINISH', '');
        this.context.commit('SET_DATE_POSTING', '');
        this.context.commit('SET_CREATED_AT', '');

        this.context.commit('SET_IS_RIGHT_TO_FOLLOW', false);
        this.context.commit('SET_IS_PRICE_RESERVE', false);
        this.context.commit('SET_BUY_NOW_PRICE_TYPE', '');
        this.context.commit('SET_IS_POSTAUCTION_SALE', false);

        this.context.commit('SET_SIZES', '');

        this.context.commit('SET_CURRENCY', { id: 0, value: '' });
        this.context.commit('SET_EXCHANGE_RATE_TYPE', '');

        this.SET_PRICE_START('');
        this.SET_PRICE_MAX('');
        this.SET_PRICE_MIN('');
        this.SET_PRICE_RESERVE('');
        this.SET_PRICE_BUY_NOW('');
        this.SET_PRICE_START('');

        this.context.commit('SET_BID_STEP', '');
        this.resetSuggestionsStateByType('category');
        this.resetSuggestionsStateByType('auction');
        this.resetSuggestionsStateByType('author');
        this.resetSuggestionsStateByType('auctionHouse');
        this.resetSuggestionsStateByType('sellerGallery');
        this.resetSuggestionsStateByType('sellerPainter');

        this.context.commit('SET_USER_WINNER', null);
        this.context.commit('SET_STATUS', null);
        this.context.commit('SET_SELLER_TYPE', '');

        this.context.commit('SET_ORDER_ID', 0);

        this.SET_IS_POSTAUCTION_SALE(false);

        this.SET_PHOTOS([]);

        this.SET_CATEGORY_SELECT(this.selectDefault);
        this.SET_CATEGORY_SELECT_LIST([]);
        this.SET_IS_ALLOW_REMOVE(false);
        this.SET_IS_ALLOW_EDIT(false);
        this.SET_IS_ALLOW_WITHDRAW(false);
        this.SET_IS_ALLOW_PUBLISH(false);

        this.SET_IS_HIDE_IMAGE(false);
        this.SET_IS_HIDE_PRICE(false);

        this.SET_BUY_NOW_PRICE_LIST(
            this.model.buyNowPriceType.list.map((type: RadioItem) => {
                delete type.hidden;

                return type;
            }),
        );

        this.SET_MODEL_FIELD_VALUE({ field: 'slug', value: '' });

        Object.keys(this.model).forEach((key) => {
            if (this.model[key] && typeof this.model[key] === 'object' && 'disabled' in this.model[key]) {
                this.SET_FIELD_DISABLED_BY_TYPE({ type: key, disabled: false });
            }

            if (key.indexOf('price') !== -1 && !pricesEstimateFieldNames.includes(key)) {
                this.SET_FIELD_DISABLED_BY_TYPE({ type: key, disabled: false });
            }
        });

        this.RESET_LOCALIZATION_BY_FIELD('name');
        this.RESET_LOCALIZATION_BY_FIELD('description');
        this.RESET_LOCALIZATION_BY_FIELD('year');
        this.RESET_LOCALIZATION_BY_FIELD('material');

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'allowedCurrencies', list: [] });
        this.DELETE_EXCHANGE_RATE_FIELD();

        this.SET_MODEL_FIELD_VALUE({ field: 'pricesEstimateMin' });
        this.SET_MODEL_FIELD_VALUE({ field: 'pricesEstimateMax' });

        this.SET_MODEL_FIELD_VALUE({ field: 'painterStartPrice' });
        this.SET_MODEL_FIELD_VALUE({ field: 'painterMaxPrice' });
        this.SET_MODEL_FIELD_VALUE({ field: 'painterBuyNowPrice' });

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        this.context.commit('RESET_ERROR_BY_FIELD', 'currency');
        this.context.commit('RESET_ERROR_BY_FIELD', 'category');
        this.context.commit('RESET_ERROR_BY_FIELD', 'categorySelect');
        this.context.commit('RESET_ERROR_BY_FIELD', 'auction');
        this.context.commit('RESET_ERROR_BY_FIELD', 'auctionHouse');
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerPainter');
        this.context.commit('RESET_ERROR_BY_FIELD', 'sellerGallery');

        this.RESET_ERROR_BY_FIELD('priceBuyNow');
        this.RESET_ERROR_BY_FIELD('priceStart');
        this.RESET_ERROR_BY_FIELD('priceMax');
        this.RESET_ERROR_BY_FIELD('priceReserve');
    }

    @Action({ rawError: true })
    resetTitleReturnName() {
        this.SET_TITLE_RETURN_NAME('');
    }
}

export default getModule(AuctionLotEntityModule);
